import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import PageHeader from "../components/PageHeader";
import {graphql} from "gatsby";
import {Col, Pagination, Row} from "antd";
import MemberListingItem from "../components/MemberListingItem";
import language from "../../data/language.yaml";
import {shuffle} from "../services/helpers";

class MemberList extends Component {
    state = {
        depth:       "",
        headerImage: undefined,
        slides:      [],

        members: [],
        pagination: {
            current: 1,
            numPosts: 0,
            page: []
        }
    };

    componentDidMount = function () {
        // const page      = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let slides      = [];
        let headerImage = "";
        let depth       = "";

        if (acfFields.header_images !== null && acfFields.header_images !== undefined) {
            if (acfFields.header_images.length > 0) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile.childImageSharp.fluid)
                );
            }

            headerImage = acfFields.header_images[0].localFile.childImageSharp.fluid;
            depth       = "full";
        } else {
            headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.default_header_image.localFile.childImageSharp.fluid;
            depth       = "thin";
        }

        this.setState({
            depth:       depth,
            headerImage: headerImage,
            slides:      shuffle(slides),

            members: shuffle(this.state.members),
            pagination: {
                current: 1,
                numPosts: this.state.members.length,
                page: this.state.members.slice(0, acfFields.members_per_page)
            }
        });
    }

    constructor(props) {
        super(props);
        const acfFields = this.props.data.wordpressPage.acf;

        /**
         * Listing setup
         */
        const locations = this.props.data.allWordpressWpLocation.nodes;
        const types     = this.props.data.allWordpressWpLocationType.nodes;

        let locationTypes = {};
        types.forEach(locationType => {
            locationTypes[locationType.wordpress_id] = locationType;
        });

        let member    = undefined;
        let memberIDs = [];
        locations.forEach(location => {
            member = location.acf.member;
            if (!memberIDs.includes(member.wordpress_id) && acfFields.location_type === location.acf.type) {
                member.type = locationTypes[location.acf.type];
                this.state.members.push(member)
                memberIDs.push(member.wordpress_id);
            }
        });
    }

    goToPage = (pageNumber, postsPerPage) => {
        let pagination = {
            current: pageNumber,
            numPosts: this.state.members.length,
            page: []
        };
        let start = (pageNumber - 1) * postsPerPage;
        pagination.page = this.state.members.slice(start, start + postsPerPage);
        this.setState({pagination: pagination});
    }

    render() {
        const page      = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        /**
         * Wrapper and header setup
         */
        let seoTitle       = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle === '') {
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        let slides      = "";
        // let headerImage = "";
        // let depth       = "";

        if (acfFields.header_images !== null && acfFields.header_images !== undefined) {
            if (acfFields.header_images.length > 1) {
                slides = [];
                acfFields.header_images.forEach(element =>
                    slides.push(element.localFile.childImageSharp.original.src)
                );
            }

            // headerImage = acfFields.header_images[0].localFile.childImageSharp.original.src;
            // depth       = "full";
        } else {
            // headerImage = this.props.data.allWordpressAcfOptions.edges[0].node.options.default_header_image.localFile.childImageSharp.original.src;
            // depth       = "thin";
        }

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={page.translations.current}
                translations={page.translations}
            >
                <PageHeader
                    title={acfFields.display_header}
                    videoID={acfFields.header_video_id}
                    image={this.state.headerImage}
                    slides={this.state.slides}
                    headerColor={acfFields.header_colour}
                    headerStyle={acfFields.header_style}
                    back={acfFields.show_back_link}
                    date=""
                    depth={this.state.depth}
                />

                <div className="member-listing">
                    <Row type="flex" gutter={70}>
                        <Col xs={{span: 24, align: "right"}}>
                            <p className="results">
                                {
                                    language.member_list.showing_results[page.translations.current]
                                        .replace('%1', this.state.pagination.page.length)
                                        .replace('%2', this.state.pagination.numPosts)
                                }
                            </p>
                        </Col>
                        {this.state.pagination.page && this.state.pagination.page.map(member => {
                            return (
                                <Col key={member.wordpress_id} className="member-col" xs={{span: 24}} md={{span: 12}} lg={{span: 8}}>
                                    <MemberListingItem member={member} locale={page.translations.current}/>
                                </Col>
                            )
                        })}
                    </Row>
                    <Row gutter={70}>
                        <Col xs={{span: 24}}>
                            <Pagination
                                total={this.state.pagination.numPosts}
                                pageSize={parseInt(acfFields.members_per_page)}
                                onChange={this.goToPage}
                            />
                        </Col>
                    </Row>
                </div>
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
    query QueryMemberListPage($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            slug
            link
            wordpress_id
            wordpress_parent
            title
            translations {
                current
                en
                cy
            }
            acf {
                meta_title
                meta_description
                og_title
                og_description
                og_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }
                twitter_title
                twitter_card_type
                twitter_description
                twitter_image {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                        }
                    }
                }

                header_style
                header_colour
                header_video_id
                header_images {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                                src
                            }
                            fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                                ... GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                display_header
                show_back_link

                listing_title
                location_type
                members_per_page
            }
        },
        allWordpressWpLocation {
            nodes {
                acf {
                    member {
                        featured_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 680, maxHeight: 350, quality: 80) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        membership_level
                        name
                        path
                        snippet
                        website_url
                        wordpress_id
                    },
                    name
                    type
                },
                translations {
                    current
                    cy
                    en
                }
            }
        },
        allWordpressWpLocationType {
            nodes {
                acf {
                    class
                    label
                    marker_icon {
                        localFile {
                            url
                        }
                    }
                    listing_colour
                },
                translations {
                    current
                    cy
                    en
                },
                wordpress_id
                wordpress_parent
            }
        },
        allWordpressAcfOptions {
            edges {
                node {
                    id
                    options {
                        default_header_image {
                            localFile {
                                childImageSharp {
                                    original {
                                        width
                                        height
                                        src
                                    }
                                    fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                                        ... GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default MemberList
